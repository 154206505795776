import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from 'firebase/app';
initializeApp({
  apiKey: "AIzaSyDtNpS17Wrdt2ii0cB9McLCPbZD5oDJHkQ",
  authDomain: "mfa-admin.firebaseapp.com",
  databaseURL: "https://mfa-admin.firebaseio.com",
  projectId: "mfa-admin",
  storageBucket: "mfa-admin.appspot.com",
  messagingSenderId: "10349904405",
  appId: "1:10349904405:web:b22ae34a4ca79af368441c",
  measurementId: "G-ECNZ2XQ6WX"
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
