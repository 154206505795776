import React, {useRef, useState} from 'react'
import './App.css';
import { useForm } from 'react-hook-form';

// material ui
import TextField from '@material-ui/core/TextField';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
// dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// icons
import { IoMdCheckmarkCircleOutline, IoMdAlert } from 'react-icons/io'
import { IoCloseOutline } from 'react-icons/io5'

// These imports load individual services into the firebase namespace.
import { getFunctions, httpsCallable } from "firebase/functions";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";

function App() {
  const {register, watch, handleSubmit, reset, formState: { errors }} = useForm();
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);
  // custom error that can come from firebase
  const [error, setError] = useState(false);
  const [dialogArgs, setDialogArgs] = useState(false);

  const password = useRef({})
  password.current = watch('password', '')

  const handleError = (errMessage) => {
    setDialogArgs({
      title: 'Error', 
      message: errMessage || 'We were unable to activate trial, please send us a message through our support chat and we will assist as soon as possible.',
      errorDialog: true
    })
    setOpen(true);
  };
  const handleOpen = () => {
    setDialogArgs({title: 'Thank you!', message: 'Your free 2 week trial has been activated. Please sign-in at mfa.studio with the password you just created and start watching now! We have also sent you a sign-in link that you can use to automatically verify your email and sign in.'})
    setOpen(true);
  };
  const handleClose = () => {
    setDialogArgs(null)
    setOpen(false);
  };

  function onSubmit(data) {
    console.log(data)

    setLoading(true)

    console.log('data:', data)

    // Attempt to create account
    const functions = getFunctions();
    const auth = getAuth()
    createUserWithEmailAndPassword(auth, data.email, data.password).then((userCredential) => {
      const claimTrial = httpsCallable(functions, 'claimTrial');
      claimTrial()
    }).then(result => {
      const data = result?.data;
      console.log('trial claimed successfully:', result);
      reset()
      handleOpen()
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('error:', error)

      if (errorCode === 'auth/email-already-in-use') {
        handleError('Account already exists! Trial can only be claimed by new accounts. Please send us a message through our support chat for further assistance.')
      } else if (errorCode === 'auth/invalid-email') {
        handleError('Email is invalid! Please make sure the email is written correctly and try again.')
      } else {
        handleError(error?.message)
      }
    }).then(() => {
      setLoading(false)
    })
  }

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '#ffc509',
      },
      error: {
        main: '#BD2323',
      },
    },
  })

  const popupDialog = ({title, message, errorDialog = false}) => {
    return (
      <Dialog fullWidth open onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <div className='close-container'>
          <IoCloseOutline onClick={handleClose}/>
        </div>
        
        <DialogTitle id="alert-dialog-title">
          {errorDialog ? (
            <IoMdAlert className="error" />
          ) : (
            <IoMdCheckmarkCircleOutline />
          )}
          {title}
        </DialogTitle>
        
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        
        <DialogActions>
          <a className='btn-dialog-container' target="_blank" href="https://mfa.studio/videos">
            <button className='btn-dialog'>
              go to mfa.studio
            </button>
          </a>
        </DialogActions>
      </Dialog>
    )
  }


  return (
    <>
      {/* top nav */}
      <nav>
        <a target='_blank' href="https://mfa.studio/">
          <div className="logo-container">
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/mfa-admin.appspot.com/o/assets%2Flogo%2Fmfa_logo.png?alt=media&token=eb3a6a02-8676-4cdc-9684-5d4a8ed784ba" 
              alt="mfa logo" 
            />
          </div>
        </a>
      </nav>
      {/* hero section */}
      <section className='hero-wrapper'>
        <div className="hero-text clamped">
          <p nowrap="true">An Exclusive Offer Just for you, from MFA.studio...</p>
          <h1 className='header'>Get free access <br/> for 14 days.</h1>
        </div>
      </section>
      {/* main section */}
      <section className='main-section-wrapper'>
        <div className='main-content semi-clamped' >
          <div className="painting-container">
            <img 
              src="https://storage.googleapis.com/mfa-admin.appspot.com/promoVideos/960x540-NO%20LOGO-MFA-LP-FREE%20TRIAL-SLIDESHOW.gif" 
              alt="quang ho painting"
            />
          </div>
          <div className="main-text">
            <h1 nowrap="true">Learn from some of the top artists in the world.</h1>
            <p>We're making complete access to all the courses on <strong>MFA.studio</strong> available to you absolutely free. Sign up today!</p>
          </div>
          {/* form */}
          <form onSubmit={handleSubmit(onSubmit)} className="form-container">
            {/* custok error that can come from firestore */}
            <ThemeProvider theme={theme}>
              <TextField
                disabled={loading}
                error={errors?.email?.message}
                label="Email" 
                variant="outlined"
                color='secondary'
                {...register('email', { 
                    required: { value: true, message: 'This Is Required' }, 
                    pattern: { value: /^[^@]+@[^@]+\.[^@]+$/, message: 'Please enter a valid email.'} 
                  })
                }
              />
              <TextField
                disabled={loading}
                error={errors?.password?.message}
                label="Create password" 
                variant="outlined"
                type="password"
                color='secondary'
                {...register('password', { 
                    required: { value: true, message: 'This Is Required' } 
                  })
                }
              />
              <TextField
                disabled={loading}
                error={errors?.confirmPassword?.message}
                label="Confirm password" 
                variant="outlined"
                type="password"
                color='secondary'
                {...register('confirmPassword', { 
                    required: { value: true, message: 'This Is Required' },
                    validate: value => {
                      return value === password.current || 'Passwords do not match.'
                    } 
                  })
                }
              />
              {errors.email && <p className="error">{errors.email.message}</p>}
              {errors.confirmPassword && <p className="error">{errors.confirmPassword.message}</p>}
            </ThemeProvider>
            <button disabled={loading} className='btn-submit' type='submit' >
              {loading ? 'Creating your account...' : 'get my free account'}
            </button>
          </form>
        </div>
      </section>
      {/* footer */}
      <footer></footer>
      {open ? popupDialog(dialogArgs) : ''}
    </>
  );
}

export default App;
